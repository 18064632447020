.handshake {
  max-width: 100px;
}

.para {
  color: #eee;
}

.logo {
  max-height: 70px;
}

.login-container {
  margin-top: 5%;
  margin-bottom: 5%;
}
.login-form-1 {
  padding: 5%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-1 h3 {
  text-align: center;
  color: #333;
}
.login-form-2 {
  padding: 5%;
  background: #6f42c1;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-2 h3 {
  text-align: center;
  color: #fff;
}
.login-container form {
  padding: 10%;
}
.btnSubmit {
  width: 50%;
  border-radius: 1.2rem;
  padding: 1.5%;
  border: none;
  cursor: pointer;
}

.login-form-2 .btnSubmit:hover {
  color: #e75788;
  background-color: #fff;
}

.login-form-1 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #0062cc;
}
.login-form-2 .btnSubmit {
  font-weight: 600;
  color: #fff;
  background-color: #e75788;
}
.login-form-2 .ForgetPwd {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}
.login-form-1 .ForgetPwd {
  color: #0062cc;
  font-weight: 600;
  text-decoration: none;
}
.message {
  position: relative;
  padding: 5px;
  margin-bottom: 20px;
  background-color: #eee;
  border-radius: 12px;
}
.success {
  background-color: #04a126 !important;
}
.noq_btn_close {
  position: absolute;
  top: 5px;
  right: 15px;
  padding: 0 8px;
  border-radius: 100px;
  color: #e21414;
}
.noq_btn_close:hover {
  background-color: #e21414;
  color: #eee;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem #ff330040;
}
