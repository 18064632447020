* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth !important;
}

body {
  background-color: #FBFCFE !important;
}